.intro {
  background-image: url(../../images/GIF.gif);
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10%;
  padding-top: 10%;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.text {
  color: rgb(255, 255, 255);
  font-family: IQOS Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 124.7%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.button {
  width: fit-content;
  height: fit-content;
  padding: 17px 47px;

  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

@media screen and (max-height: 800px) {
  .button {
    padding: 10px 25px;
    font-size: 26px;
  }
}
