.game {
  overflow: hidden;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 10%;
}

.text {
  color: rgb(255, 255, 255);
  font-family: IQOS Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 124.7%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.balls {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 20px;
  padding: 20px;
}

.ball {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgb(243, 50, 50);
  border: none;
  position: relative;
  justify-self: center;
  align-self: center;
}

.icon {
  position: absolute;
  top: -30px;
  height: 20px;
  width: 20px;
}

@media screen and (max-height: 800px) {
  .balls {
    gap: 5px;
  }
}
