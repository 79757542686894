.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 33, 63, 0.7);
  backdrop-filter: blur(7.5px);
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  padding-top: 20%;
}

.overlayFirst {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 33, 63, 0.7);
  backdrop-filter: blur(7.5px);
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  padding-top: 20%;
}

.container {
  border-radius: 36px;
  background: rgb(255, 255, 255);
  padding: 60px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 20px;
}

.title {
  color: rgb(0, 36, 96);
  font-family: IQOS Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 107.2%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}

.text {
  color: rgb(0, 36, 96);
  font-family: IQOS Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: center;
}

.textFrame {
  color: rgb(0, 36, 96);
  font-family: IQOS Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: center;
  border: 1px solid rgb(0, 36, 96);
  padding: 10px;
}

.textSmall {
  color: rgb(0, 36, 96);
  font-family: IQOS Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: center;
}

.button {
  width: fit-content;
  height: fit-content;
  padding: 17px 47px;

  border-radius: 64.278px;
  border: 5.596px solid rgb(18, 76, 124);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: rgb(18, 76, 124);
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

.whiteButton {
  width: fit-content;
  height: fit-content;
  padding: 17px 47px;
  margin-top: 10px;
  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

.checkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 108px;
  height: 108px;
  position: absolute;
  top: -80px;
  background: rgb(33, 170, 97);
}

@media screen and (max-width: 400px) {
  .container {
    padding: 40px 10px 20px 10px;
  }

  .text {
    font-size: 16px;
  }

  .textSmall {
    font-size: 12px;
  }
}

@media screen and (max-height: 800px) {
  .overlay {
    padding-top: 10%;
    justify-content: flex-end;
  }
  .whiteButton {
    padding: 10px 25px;
    font-size: 26px;
  }
}
