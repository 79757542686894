.ball {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: url(../../images/redball.svg);
  background-size: cover;
  border: none;
  position: relative;
  justify-self: center;
  align-self: center;
  opacity: 0;
  padding: 0;
}

.icon {
  position: absolute;
  top: -30px;
  height: 20px;
  width: 20px;
  left: 23px;
}

@media screen and (max-width: 400px) {
  .ball {
    width: 40px;
    height: 40px;
  }
  .icon {
    left: 13px;
  }
}
