.final {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.container {
  /* background: url(../../images/bubble.png); */
  background-position: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  width: 100%;
  /* height: 100%; */
  top: 0;
}

.title {
  position: absolute;
  top: calc(50% - 65px);
  color: rgb(255, 255, 255);
  font-family: IQOS Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 149.7%;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
}

.button {
  width: fit-content;
  height: fit-content;
  padding: 17px 47px;
  margin-top: 10px;
  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
  text-transform: uppercase;
  position: absolute;
  bottom: 20px;
}

/* .image {
  width: 100%;
  position: absolute;
  top: 10%;
} */

@media screen and (max-width: 400px) {
  .button {
    font-size: 22px;
  }
}
/* 
@media screen and (max-height: 800px) {
  .final {
    padding-top: 45%;
  }
} */
