.guide {
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: rgb(255, 255, 255);
  font-family: IQOS Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 107.2%;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.text {
  color: var(--Slate-Grey, #34303d);
  text-align: left;

  /* New Mobile_B1_Regular */
  font-family: IQOS Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  padding: 20px;
  border-radius: 16px;
  background: var(--Soft-Turquoise, #e9ffff);
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 80px;
}

.button {
  width: fit-content;
  height: fit-content;
  padding: 17px 47px;

  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

.red {
  position: absolute;
  right: -10px;
  bottom: -20px;
}
.blue {
  position: absolute;
  left: -10px;
  bottom: -20px;
}

@media screen and (max-height: 800px) {
  .title {
    margin-bottom: 18px;
    font-size: 34px;
  }
  .button {
    padding: 10px 25px;
    font-size: 26px;
  }
}
